"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListboxDirective = void 0;
const ng = window.angular;
function isFunction(value) {
    return value instanceof Function;
}
function ListboxDirective() {
    const onFocus = (event) => {
        event.preventDefault();
        // Try to remove the focus from this element.
        // This is important to always perform, since just focusing the previously focused element won't work in Edge/FF, if that element is unable to actually get the focus back (became invisible, etc.): the focus would stay on the current element in such a case
        const currentTarget = event.currentTarget;
        if (currentTarget !== null && isFunction(currentTarget.blur)) {
            currentTarget.blur();
        }
        // Try to set focus back to the previous element
        const relatedTarget = event.relatedTarget;
        if (relatedTarget !== null && isFunction(relatedTarget.focus)) {
            relatedTarget.focus();
        }
    };
    return {
        restrict: 'A',
        //@ts-ignore
        link: ($scope, $element) => {
            const e = $element[0];
            e.addEventListener('focus', onFocus, false);
        }
    };
}
exports.ListboxDirective = ListboxDirective;
ListboxDirective.$inject = [];
exports.default = ListboxDirective;
