"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetaService = void 0;
const ng = window.angular;
class MetaService {
    constructor($window, $rootScope) {
        this.$window = $window;
        this.$rootScope = $rootScope;
        this.backTitle = ng.copy(this.$window.document.title);
        this.backDesc = ng.copy(ng.element(this.$window.document.getElementsByName('description')).attr('content')) || "";
        console.log(this.$rootScope);
    }
    setMetaTags(data) {
        if ('title' in data) {
            this.$window.document.title = data.title || this.backTitle;
        }
        if ('desc' in data) {
            ng.element(this.$window.document.getElementsByName('description')).attr('content', data.desc || this.backDesc);
        }
    }
}
exports.MetaService = MetaService;
MetaService.$inject = ["$window", "$rootScope"];
exports.default = MetaService;
