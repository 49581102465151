"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.wsServiceProvider = exports.WsService = exports.genericRetryStrategy = void 0;
const ng = window.angular;
const webSocket_1 = require("rxjs/webSocket");
//@ts-ignore
const randomstring = __importStar(require("randomstring"));
//@ts-ignore
const config_NODE_ENV_1 = require("../config-NODE_ENV");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
let lastAttemptReset = 0;
let lastAttempt = 0;
const genericRetryStrategy = ({ maxRetryAttempts = 3, scalingDuration = 1000, excludedStatusCodes = [] } = {}) => (attempts) => {
    return attempts.pipe((0, operators_1.mergeMap)((error, i) => {
        const retryAttempt = i - lastAttemptReset + 1;
        lastAttempt = retryAttempt;
        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (retryAttempt > maxRetryAttempts ||
            excludedStatusCodes.find(e => e === error.status)) {
            return (0, rxjs_1.throwError)(error);
        }
        console.debug(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`, error);
        // retry after 1s, 2s, etc...
        return (0, rxjs_1.timer)(retryAttempt * scalingDuration);
    }), (0, operators_1.finalize)(() => console.log('We are done!')));
};
exports.genericRetryStrategy = genericRetryStrategy;
// переподписывать мультиплексор при восстановлении соединения
const createWebSocket = (subject, openSubject) => {
    return new rxjs_1.Observable(observer => {
        try {
            const Subscribe = () => {
                return subject.subscribe((data) => {
                    return observer.next(data);
                }, (err) => {
                    if (err.code == 1006) {
                        return observer.next(null);
                    }
                    else {
                        return observer.error(err);
                    }
                }, () => {
                    console.log('online this.closed$ complete');
                    observer.complete();
                });
            };
            let subscription = Subscribe();
            const subscriptions = [
                subscription
            ];
            if (openSubject) {
                const subscribeConnection = openSubject.pipe((0, operators_1.skip)(1)).subscribe((event) => {
                    if (event.type == 'open') {
                        subscription = Subscribe();
                    }
                });
                subscriptions.push(subscribeConnection);
            }
            return () => {
                if (!subscription.closed) {
                    for (const i of subscriptions) {
                        i.unsubscribe();
                    }
                }
            };
        }
        catch (error) {
            observer.error(error);
        }
    });
};
class WsService {
    constructor($cookies, prefix) {
        this.$cookies = $cookies;
        this.prefix = prefix;
        this.closed = true;
        this.closed$ = new rxjs_1.Subject();
        this.ws_token = $cookies.get('ws_token');
        if (!this.ws_token) {
            this.ws_token = randomstring.generate(12);
            $cookies.put('ws_token', this.ws_token);
        }
        console.debug('this.ws_token', this.ws_token);
        this.ws_subject = (0, webSocket_1.webSocket)({
            url: `${config_NODE_ENV_1.config.channels}${this.prefix || ''}?token=${this.ws_token}`,
            openObserver: this.closed$,
            closeObserver: this.closed$,
        });
        this.ws_subject.pipe((0, operators_1.retryWhen)((0, exports.genericRetryStrategy)({ maxRetryAttempts: 3 })), (0, operators_1.catchError)(error => (0, rxjs_1.of)(error))).subscribe();
        this.wsOnlineUsers = createWebSocket(this.ws_subject.multiplex(() => '', () => '', (message) => {
            return message.type === 'online.send';
        }), this.closed$);
        this.closed$.subscribe((event) => {
            this.closed = event.type !== 'open';
            if (event.type == 'open') {
                lastAttemptReset = ng.copy(lastAttempt);
            }
        });
    }
    static wsServiceFactory($cookies) {
        return new WsService($cookies);
    }
}
exports.WsService = WsService;
WsService.$inject = ["$cookies"];
function wsServiceProvider() {
    let prefix = '';
    this.setPrefix = (newPrefix) => {
        prefix = newPrefix;
    };
    this.$get = ['$cookies', ($cookies) => {
            // return new WsService($cookies, prefix);
        }];
    return this;
}
exports.wsServiceProvider = wsServiceProvider;
exports.default = WsService;
