"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalService = void 0;
const ng = window.angular;
class ModalService {
    constructor($document, $rootScope, $compile, $q, $timeout, $location, $templateCache) {
        this.$document = $document;
        this.$rootScope = $rootScope;
        this.$compile = $compile;
        this.$q = $q;
        this.$timeout = $timeout;
        this.$location = $location;
        this.$templateCache = $templateCache;
        this.closedAll = true;
        this.options = {};
        this.instances = {};
        this.closeDefer = this.$q.defer();
    }
    setOptions(_options) {
        ng.extend(this.options, _options);
    }
    open(_options) {
        const options = ng.extend(ng.copy(this.options), _options);
        let instanceKey = options.id;
        if (options.strategy == 'filo') {
            instanceKey += '_' + Math.random().toString(36).substr(2, 9);
        }
        let when = true;
        if (Object.keys(this.instances).indexOf(instanceKey) != -1 && options.strategy == 'fifo') {
            when = this.instances[instanceKey].defer.promise;
        }
        else if (options.strategy == 'if_close_all') {
            when = ng.equals(this.instances, {});
        }
        else if (options.strategy == 'wait_close_all') {
            when = new Promise((resolve, reject) => {
                this.closeDefer.promise.then(() => {
                }, () => {
                    //when close(__all__)
                    reject(false);
                }, (closedAll) => {
                    if (closedAll) {
                        resolve(true);
                    }
                });
            });
        }
        else if (options.strategy == 'override') {
            for (const key of Object.keys(this.instances)) {
                this.instances[key].defer.reject(`Modal ${key} close. ${instanceKey} override it`);
            }
        }
        else if (Object.keys(this.instances).indexOf(instanceKey) != -1) {
            when = false;
        }
        else {
            this.instances[instanceKey] = {
                defer: this.$q.defer()
            };
        }
        // console.log(options, when)
        return this.$q.when(when).then((when) => {
            // console.log(
            //     'when', instanceKey, when
            // )
            if (when != false) {
                if (Object.keys(this.instances).indexOf(instanceKey) == -1) {
                    this.instances[instanceKey] = {
                        defer: this.$q.defer()
                    };
                }
                const modalScope = this.$rootScope.$new(false, options.scope);
                ng.extend(modalScope, options.extraContext ? options.extraContext : {});
                modalScope.modalInstance = this;
                if (options.strategy == 'filo') {
                    [modalScope.id, modalScope.$random] = instanceKey.split('_');
                }
                else {
                    modalScope.id = instanceKey;
                }
                let modalTemplate = '';
                if (options.template) {
                    modalTemplate = `modal-template=${escape(options.template)}`;
                }
                else if (options.templateUrl) {
                    const template = this.$templateCache.get('endGame.html');
                    if (template != undefined)
                        modalTemplate = `modal-template=${escape(template)}`;
                }
                let alert_classes = 'component-modal';
                if (options.forced) {
                    alert_classes += ' component-modal-forced';
                }
                const modalContent = this.$compile(`<${options.component} modal-instance="modalInstance" ${modalTemplate} class="${alert_classes}"></${options.component}>`)(modalScope, (_element) => {
                    ng.element(options.target ? options.target : window.document.body).append(_element);
                    setTimeout(() => {
                        if (_options.afterCompiled != undefined) {
                            _options.afterCompiled();
                        }
                    });
                });
                ng.extend(this.instances[instanceKey], {
                    modalContent: modalContent,
                    modalScope: modalScope
                });
                this.instances[instanceKey].defer.promise.finally(() => {
                    modalContent.remove();
                    modalScope.$destroy();
                    delete this.instances[instanceKey];
                    this.$timeout(() => {
                        this.closedAll = ng.equals(this.instances, {});
                        this.closeDefer.notify(this.closedAll);
                    }, 50);
                });
                return this.instances[instanceKey].defer.promise;
            }
            else {
                const defer = this.$q.defer();
                defer.reject(`Skip same modal: ${instanceKey}`);
                return defer.promise;
            }
        }, (err) => {
            console.log(err);
        });
    }
    close(id, result) {
        if (id == '__all__') {
            // reject для того чтобы промисы в чайне токже не стартовали, для fifo например или wait close all
            return new Promise((resolve, reject) => {
                const promises = [];
                for (const key of Object.keys(this.instances)) {
                    this.instances[key].defer.reject('close __all__');
                    promises.push(this.instances[key].defer.promise);
                }
                this.closeDefer.reject('close __all__');
                this.$q.all(promises).finally(() => {
                    resolve('reject __all__ promises');
                });
            });
        }
        else {
            if (Object.keys(this.instances).indexOf(id) != -1) {
                this.instances[id].defer.resolve(result);
                return this.instances[id].defer.promise;
            }
            return new Promise(resolve => resolve(result));
        }
    }
    static modalServiceFactory($document, $rootScope, $compile, $q, $timeout, $location, $templateCache) {
        return new ModalService($document, $rootScope, $compile, $q, $timeout, $location, $templateCache);
    }
}
exports.ModalService = ModalService;
ModalService.$inject = ['$document', '$rootScope', '$compile', '$q', '$timeout', '$location', '$templateCache'];
ModalService.modalServiceFactory.$inject = ['$document', '$rootScope', '$compile', '$q', '$timeout', '$location', '$templateCache'];
exports.default = ModalService;
