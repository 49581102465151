"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.configServiceProvider = exports.ConfigService = void 0;
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class ConfigService {
    constructor($cookies, $q, defaultConfig) {
        this.$cookies = $cookies;
        this.$q = $q;
        this.defaultConfig = defaultConfig;
        this.cookieSettings = (this.$cookies.getObject('cookie_settings') || this.defaultConfig);
        this._settings = new rxjs_1.BehaviorSubject(this.cookieSettings);
        this.settings$ = (0, rxjs_1.merge)(this._settings).pipe(
        // startWith(this.cookieSettings),
        (0, operators_1.scan)((state, curr) => (Object.assign(Object.assign(Object.assign({}, this.defaultConfig), state), curr)), {}), (0, operators_1.tap)((value) => console.debug(value)));
        this.settings$.subscribe((value) => {
            this.$cookies.putObject('cookie_settings', value);
            this.cookieSettings = value;
        });
        const defer = this.$q.defer();
        this.ready = defer.promise;
        this.$q.all(ConfigService.promises).then(() => {
            defer.resolve();
        });
        const logoLink = document.getElementById('logo');
        this.logoLink$ = (0, rxjs_1.fromEvent)(logoLink, 'click').pipe((0, operators_1.filter)(event => event.cancelable), (0, operators_1.tap)(event => {
            event.preventDefault();
        }));
    }
    setCookieSettings(cookieSettings) {
        this._settings.next(cookieSettings);
    }
    getCookieSettings() {
        return this.cookieSettings;
    }
}
exports.ConfigService = ConfigService;
ConfigService.$inject = ["$cookies", "$q"];
ConfigService.promises = [];
exports.default = ConfigService;
function configServiceProvider() {
    let defaultConfig = {
        cookie_show: '',
        dark_mode: 'no',
    };
    this.setDefaultConfig = (config) => {
        defaultConfig = Object.assign(Object.assign({}, defaultConfig), config);
    };
    this.promises = ConfigService.promises;
    this.addPromise = (promise) => {
        ConfigService.promises.push(promise);
    };
    this.$get = ['$cookies', '$q', ($cookies, $q) => {
            return new ConfigService($cookies, $q, defaultConfig);
        }];
    return this;
}
exports.configServiceProvider = configServiceProvider;
