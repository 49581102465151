"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TestComp = void 0;
const ng = window.angular;
class TestComponentCtrl {
    constructor($scope, ModalServiceFactory) {
        this.$scope = $scope;
        this.ModalServiceFactory = ModalServiceFactory;
    }
    open(id, subId, strategy) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.ModalServiceFactory.open({
                id: id,
                template: require('@src/app/components/alert/alert-test.ng.html'),
                component: 'alert-comp',
                scope: this.$scope,
                strategy: strategy,
                extraContext: {
                    title: id,
                    subId: subId,
                }
            });
        });
    }
    $onInit() { }
}
TestComponentCtrl.$inject = ['$scope', 'ModalServiceFactory'];
exports.TestComp = {
    bindings: {},
    template: require("./test.ng.html"),
    controller: TestComponentCtrl,
    controllerAs: '$ctrl'
};
