"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EqualsFilter = void 0;
const ng = window.angular;
function EqualsFilter() {
    return function (value1, value2) {
        return ng.equals(value1, value2);
    };
}
exports.EqualsFilter = EqualsFilter;
EqualsFilter.$inject = [];
exports.default = EqualsFilter;
