"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrustUrlFilter = void 0;
const ng = window.angular;
function TrustUrlFilter($sce) {
    return function (url, func) {
        if (func) {
            return $sce.trustAsResourceUrl(func(url));
        }
        return $sce.trustAsResourceUrl(url);
    };
}
exports.TrustUrlFilter = TrustUrlFilter;
TrustUrlFilter.$inject = ['$sce'];
exports.default = TrustUrlFilter;
