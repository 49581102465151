"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkipService = void 0;
const ng = window.angular;
//@ts-ignore
const config_NODE_ENV_1 = require("@src/app/config-NODE_ENV");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class SkipService {
    constructor($rootScope, $http, $timeout) {
        this.$rootScope = $rootScope;
        this.$http = $http;
        this.$timeout = $timeout;
        this.clickSkip$ = new rxjs_1.Subject();
        this.show = false;
        this.showSkip = false;
        this.skipCount = config_NODE_ENV_1.config.skipCount;
        this.timer = ng.copy(config_NODE_ENV_1.config.skipTimeout);
        this.hideBySkipCount = false;
    }
    skipShow() {
        this.clickSkip$.next();
    }
    getSkipObs(skip) {
        if (skip > this.skipCount) {
            // this.show = false;
            return (0, rxjs_1.of)(null);
        }
        this.load$ = (new rxjs_1.Subject()).pipe((0, operators_1.startWith)(null), 
        //first(), // один раз разпускать до обновления страницы
        (0, operators_1.tap)((value) => {
            this.showSkip = false;
            this.timer = ng.copy(config_NODE_ENV_1.config.skipTimeout);
        }), (0, operators_1.switchMap)(() => {
            if (this.hideBySkipCount) {
                this.show = false;
                this.showSkip = false;
                throw "hideBySkipCount";
            }
            else {
                return (0, rxjs_1.timer)(0, 1000).pipe((0, operators_1.take)(config_NODE_ENV_1.config.skipTimeout + 1), (0, operators_1.finalize)(() => {
                    this.$http.post('/skip/', {}).then((resp) => {
                        this.hideBySkipCount = resp.data.skip >= this.skipCount;
                    });
                }));
            }
        }), (0, operators_1.tap)((value) => {
            console.debug('tap skip load', value);
            this.$timeout(() => {
                this.show = true;
                this.timer = config_NODE_ENV_1.config.skipTimeout - value;
                if (this.timer < 1) {
                    this.timer = undefined;
                    this.showSkip = true;
                }
            });
        }), (0, operators_1.filter)((value) => {
            return value >= config_NODE_ENV_1.config.skipTimeout;
        }), (0, operators_1.switchMap)(() => {
            return this.clickSkip$.pipe((0, operators_1.debounceTime)(300), (0, operators_1.tap)(() => {
                this.$timeout(() => {
                    this.show = false;
                });
            }));
        }), (0, operators_1.catchError)((err) => {
            return (0, rxjs_1.of)(this.skipCount);
        }));
        return this.load$;
    }
}
exports.SkipService = SkipService;
SkipService.$inject = ['$rootScope', '$http', '$timeout'];
exports.default = SkipService;
